import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({ language, seo = {} }) => {
  const title = `${seo["og:title"] ? seo["og:title"] + " - " : ""}Carbfix`;
  return (
    <>
      <Helmet>
        <html lang={language} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>{title}</title>

        {Object.keys(seo).map(
          (key) =>
            seo[key] && <meta key={key} property={key} content={seo[key]} />
        )}
      </Helmet>
    </>
  );
};

export default SEO;
